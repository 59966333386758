:root{
  --color-main: #82bd69;
  --color-strong: #3d692c;
  --color-light : #d3eac8;

  --front-basic:16px;
  --font-strong : 18px;
  --font-extra-strong :22px;
}

.react-calendar {
  width: 600px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  margin-inline: auto;
}

.react-calendar__navigation{
  display: grid;
  grid-auto-flow: column;
  padding-bottom:1rem;
  padding-bottom:0.8rem;

}


.react-calendar__navigation button {
  color: var(--color-main);
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}


.react-calendar__navigation__label__labelText {
  font-weight: bold;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

abbr[title] {
  text-decoration: none;
}


.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: var(--color-main);
  border-radius: 6px;
}

.react-calendar__tile--now {
  background: var(--color-light);
  border-radius: 6px;
  font-weight: bold;
  color:var(--color-main);
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--color-light);
  border-radius: 6px;
  font-weight: bold;
  color: var(--color-main);
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}

.react-calendar__tile--active {
  background: var(--color-main);
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--color-main);
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #f8f8fa;
  color: var(--color-main);
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: var(--color-main);
  color: white;
}

.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: var(--color-main);
  color: white;
}

.calendar-custom .react-calendar__month-view__weekdays{
  display: grid;
  text-align: center;
  padding:0.7rem 0

}

.react-calendar__navigation__label[type="button"]{
  font-size: 18px;
}

.react-calendar__month-view__days__day[type="button"]{
  padding:1rem 0;
}