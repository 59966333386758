html, body {
  margin: 0;
  padding :0
  
}

code {


      font-family: system-ui,sans-serif, 'Helvetica Neue';

    
}
