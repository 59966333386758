@import 'reset.css';
:root{
  --color-main: #82bd69;
  --color-strong: #3d692c;
  --color-light : #d3eac8;

  --font-small:12px;
  --font-basic:16px;
  --font-strong : 18px;
  --font-extra-strong :22px;

  --border-basic : #c9c9c9;


  --width-min:300px;
  --width-max:680px;
  
}

/* *{
  outline: 1px solid red;
} */
a{
  color :#000;
}

a:visited {
  color : black;
}


h1{
  font-size: 32px; 

}
h2{
  font-size: 26px;
  margin-bottom: 10px;
}

p{
  margin: 4px 0;
}

button{
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  background: #00000000;

}


button:hover{
  background: var(--color-strong);
  color : #fff;

}


.main{
  height: 100vh;
  min-width: var(--width-min);
  max-width: var(--width-max);
  display: grid;
  margin-inline: auto;
  padding-bottom: 68px;
  padding-left: 4.5rem;
  padding-right: 4.5rem;
  box-sizing: border-box;
  box-shadow: 0px 9px 10px 1px rgb(0 0 0 / 15%);
  /* border-left: 1px solid var(--color-light);
border-right: 1px solid var(--color-light); */
}


.main-main{
  height: 100%;
  min-width: var(--width-min);
  max-width: var(--width-max);
  display: grid;
  margin-inline: auto;
  padding-bottom: 68px;
  padding-left: 4.5rem;
  padding-right: 4.5rem;
  box-sizing: border-box;
  box-shadow: 0px 9px 10px 1px rgb(0 0 0 / 15%);
  /* border-left: 1px solid var(--color-light);
border-right: 1px solid var(--color-light); */
}

@media screen and (max-width : 680px){
  .main{
    padding-left: 1rem;
    padding-right: 1rem;
    height: 100%;
  }

}

/* Header area */
.header-wrap{
  position: fixed;
  bottom: 0;
  left:0;
  right:0;
  width:100%;
  height: 68px;
  display: grid;
  align-items: center;

  background :#fff;
  box-sizing: border-box;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 3;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.header-wrap ul {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-around;

  /* flex-direction: column;
  align-items: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-wrap a{
  display: block;
  width: 38px;
}

.header-search{
  width:36px;
}

.header-myprofile{
  width: 34px;
}

.App-link {
  color: #61dafb;
}

.header-wrap .active > svg{
  display: block;
  stroke : var(--color-main);
}

.header-wrap svg{
  stroke: #4b4b4b;
  fill: rgba(0, 0, 0, 0);
}



footer{
  min-width: var(--width-min);
  max-width: var(--width-max);
  padding:3rem 24px 6rem 24px;
  font-size: 12px;
  color : #999;
  background: #efefef;
  margin-inline: auto;
  box-sizing: border-box;
  box-shadow: 0px 9px 10px 1px rgb(0 0 0 / 15%);

}



.ppt, .git-server, .git-client{
  position: fixed;
  right : 7%;
  width: 50px;
  height: 50px;
  font-size: 10px;

  border-radius: 50%;
  cursor: pointer;

  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.git-client{
  bottom : 34%;
  color:#fff;
  background: var(--color-strong);
}

.git-server{
  bottom : 22%;
  background:var(--color-main)
}


.ppt{
  bottom : 10%;
  background: var(--color-light);
  color : #555555
}


/* Data List Component */
.data-list-area {
  display: grid;
  /* place-content: center; */
  position: relative;
  padding : 2rem 0 ;
  grid-row-gap:70px;
}

.data-list-each{
  display: grid;
  justify-content:center;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
}

.data-list-description{
  max-height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 20px;
  line-height: 18px;
}

.data-img-wrap{

  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 20px;
}

.data-list-img{
display: block;
max-width: 100%;
margin-bottom: 20px;
border-radius: 12px;
}

.data-list-detail p:nth-last-of-type(1){
  color:#000;
  margin-top: 10px;
  font-weight: bold;
}

.data-list-location{
  color :#000;
  font-weight: 600;
}

.data-list-price{
  color : #000;
  font-size: var(--font-strong);
  font-weight: 600;
  margin-top: 20px;
}

.data-list-detail{
  color : #656565;
  position: relative;
  width: auto;
}

.data-list-score{
  position: absolute;
  top: 0;
  right: 10px;
  color : #000;
}

.data-list-button{
  display: block;
  width: 100%;
  height:48px;
  border-radius: 8px;
  margin-top : 30px;
  cursor: pointer; 
  border: 1px solid var(--color-light);
}

.data-list-event{
  position: absolute;
  left: 10px;
  top: 8%;
  background: #fff;
  padding: 0.3rem;
  border-radius: 4px;
}



/* bookingConfirm */






/* search area */
.search-wrap{
  height: 54px;
  border: 1px solid var(--color-main);
  border-radius: 30px ;
  overflow: hidden;
  display: flex;
  margin:30px 10px 0;
  width: fit-content;
  margin-inline: auto;

}

.search-wrap form{
  display:inline-grid;
  grid-template-columns: 240px minmax(0, 1fr);

}

.search-wrap input{
  border: 0;
  outline: transparent;
  background: transparent;
  padding-left: 20px;
  
}

.search-btn{
  border: 0;
  height: 100%;
  width: 100%;
  padding:0 30px;
  color :var(--color-main);
}

.search-svg{
  display: block;
}





















/* Data detail area */
.detail-main, 
.confirm-main
{
  min-width: var(--width-min);
  max-width: var(--width-max);
  display: grid;
  /* place-content: center; */
  /* place-items: center; */
  margin-inline: auto;
  padding-bottom: 68px;
  padding-left: 4.5rem;
  padding-right: 4.5rem;
  box-shadow: 0px 9px 10px 1px rgb(0 0 0 / 15%);
  box-sizing: border-box;
}


@media screen and (max-width : 680px){
  .detail-main,
  .confirm-main{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.detail-header,
.confirm-header{
  position:relative;
  top:0;
  left:0;
  right:0;
  width:100%;
  height:64px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  place-items: center;
  padding:0  1.2rem;
  border-bottom: 1px solid #dddddd;
  z-index: 1;
  box-sizing: border-box;
}

.detail-back-btn,
.confirm-back-btn{
  cursor: pointer;
}

.detail-area{
  width: auto;
  display: grid;
  /* place-content: center; */
  position: relative;
  grid-row-gap:4.5rem;
  
}


.detail-describe{
  height: 100%;
  position: relative;
}

.detail-describe > P:nth-last-of-type(1){
  margin-top: 20px;
  line-height: 20px;
}

.detail-score-wrap{
  position: absolute;
  top:0;
  right:10px;
  background-color: #fff;
  padding: .2rem .4rem;
  border-radius: 4px;
}

.detail-area img{
  display: block;
  border-radius: 8px;;
  max-width: 100%;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.8);
}

.detail-area h2{
  margin-top: 20px;
  margin-bottom: 20px;
}


.detail-number-add-describe{
  font-size: 12px;
}

.decrease:hover{
  background: var(--color-light);
}


.increase:disabled, 
.decrease:disabled{
  cursor: not-allowed;
}


.detail-basic-info, .detail-benefit-info, .detail-cancel-info{
  padding: 0 1rem;
  box-sizing: border-box;
  color : #757575;
  margin-bottom: 2rem;;
}
.detail-basic-info-theme, 
.detail-benefit-info-theme, 
.detail-cancel-info-theme,
.detail-calendar-theme,
.detail-number-add-theme{
  width: 100%;
  color: #000;
  font-weight: bold;
  font-size: var(--font-strong);
  margin-bottom: 20px;
}

.detail-calendar .react-calendar{
  width:auto;
}

.detail-button-area{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-width:var(--width-min);
  max-width: var(--width-max);
  height: 68px;
  margin-inline: auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  place-items: center;

  background: #fff;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.detail-info-wrap{
  text-align: center;
}

.detail-button-area .detail-price{
  margin-top: 10px;

}

.detail-price-won{
  font-size: 16px;
  /* font-weight: 600; */
}


.detail-submit-button {
  padding: 0 2rem;
  width: auto;
  height:48px;
  border-radius: 8px;
  border:1px solid var(--color-light)
}




.detail-calendar-day-check{
  color : #999;
  font-style: italic;
}









.confirm-extra-number-theme,
.confirm-user-info-theme,
.confirm-accommodation-info-theme,
.confirm-booking-info-theme
{
  width: 100%;
  color: #000;
  font-weight: bold;
  font-size: var(--font-strong);
  margin-bottom: 20px;
}

.confirm-area{
  width: auto;
  display: grid;
  position: relative;
  grid-row-gap:4.5rem;
}


.confirm-user-info,
.confirm-accommodation-info,
.confirm-extra-numbers{
  margin-inline : 2rem;
}

.confirm-user-info,
.confirm-accommodation-info{
  padding-bottom: 30px;
}



.confirm-extra-number-theme{
  margin-bottom: 10px;
}

.confirm-extra-numbers > span{
  width: 100%;
  display: inline-block;
  text-align: right;
  margin-top: 4px;
  font-size: 12px;
  color:#999;
}

.confirm-number-add{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width:100%;
  padding:1rem;
  border: 2px solid var(--color-main);
  border-radius: 12px;
  box-sizing: border-box;
}

.confirm-number-add > div >p{
  position: relative;
}
.detail-number-add-describe,
.detail-number-add-describe
{
  position: absolute;
  left:6px;
  bottom:-6px;
}

.confirm-number-add button{
  padding: .5rem 1rem;
  border-radius: 8px;
}


.confirm-calendar-Sday{
  color : var(--color-main);
  font-weight:800;
}


.confirm-calendar-Eday{
  color : var(--color-strong);
  font-weight:800;
}

.confirm-booking-info{
  border-top: 2px solid #c9c9c9;
  padding-inline: 2rem;
  padding-top:2rem;
}

.confirm-finial-price {
  text-align: right;
  font-weight: 700;
  margin-top: 20px;
  padding-top: 19px;
  border-top: 1px solid #c9c9c9;
}

.confirm-finial-price >span {
  font-weight: 700;
  font-size: var(--font-extra-strong);
}



.confirm-booking-date{
  display: inline-block;
}

.confirm-brief-booking p:nth-last-of-type(1){
  text-align: right;
  margin-top: 1rem;
  /* font-size: var(--font-strong); */
}

.confirm-brief-booking > p >span{
  font-weight: bold;
}

.confirm-brief-date-basic{
  text-align: right;
  padding: 1rem 0;
  margin : 0.8rem 0;
  /* border-top: 1px solid #c9c9c9; */
  border-bottom: 1px solid #c9c9c9;
  font-weight: bold;
}

.confirm-payment-button{
  width:auto;
  margin-inline:auto;
  padding: 1.2rem 3rem;
  background:  var(--color-main);
  border-radius: 12px;
  font-weight: bold;
}









/* Search Button CSS */
.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px; /* Optional rounded corners */
  transform: translateY(100%); /* Start hidden off-screen */
  transition: transform 0.3s ease-in-out; /* Animation settings */
  z-index: 100; /* Set a high z-index to ensure it's on top */
  height :80dvh;
  overflow: auto;
}

.popup.open {
  transform: translateY(0); /* Slide up to visible position */

}

body.overflow-hidden {
  /* Prevent body scrolling on popup open */
  overflow: true;
}

.search-detail-act-btn{
  position: absolute;
  top:50px;
  right :12%;

}

.search-detail-form-wrap {
  display: grid;
  justify-items: center;
  row-gap: 20px;
}

.search-detail-btn{
  position: absolute;
  right:10%;
}





.login-page-wrap{

  height: 100svh;
} 



.login-wrap{
  position: sticky;
  top: 4rem;
  width: 320px;
  margin-inline: auto;
  box-sizing: border-box;

}

.login-wrap > p{
padding: 1rem 0 ;
position: relative;
}

.login-test-key > span {
  /* visibility: hidden; */
  font-size: var(--font-small);
  color: #757575;

  position: absolute;
  bottom:100%;
  left: 50%;
  width: max-content;
  padding:.9rem .7rem;
  border-radius: 8px;
  transform: translate(-50%, 200%);

  background: var(--color-light);
  z-index: 1;
  }



.login-wrap button{
  width: 100%;
  padding: 1rem 3.2rem;
  border-radius: 12px;
  color : #000;
}

.login-wrap form{
  margin-bottom:50px;
}

.login-wrap label{
  position : absolute;
  top : 8px;
  left: 16px;
  /* font-size: 1em; */
  transition : font-size 0.3s ease;
}

.email-wrap:focus-within input:focus + label, 
.pw-wrap:focus-within input:focus + label {
  /* font-size: 12px; */
  top:-10px;
  left : -10px;
}

.pw-wrap{
  margin: 1rem 0;
}

.login-wrap input[type=email],
.login-wrap input[type=password]
{
  font-family: system-ui;
  font-size: 17px;
  border: none;
  outline: none;
  min-width:280px;
}

.email-wrap, .pw-wrap{
  border: 1px solid;
  padding: 1.8rem 1rem 6px;
  border-radius: 8px;
  transition : border-color .6s;
  position: relative;
}
  
.email-wrap:focus-within, .pw-wrap:focus-within{
  border-color: var(--color-main);
  font-size: 10px;
}

.login-wrap button[type=submit]{
  border: 1px solid var(--color-light);
  color: var(--color-strong);
}

.login-wrap button[type=submit]:hover{
  color: #fff;
}
.naver-btn{
  background: #03c75a;
  color : #fff;
  font-weight: bold;
}










/* My page CSS Area */

.mypage-user{
  margin-top: 30px;
}

.mypage-header{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  /* line-height: 64px; */
  border-bottom: 1px solid var(--border-basic) ;
}

.mypage-wrap{
  height: 100vh;
  margin-inline: auto;
  width: 100%;
}


.mypage-user-wrap{
  margin-bottom: 50px;
}

.mypage-user-wrap img{
  display: inline-block;
  width:50px;
  height: 50px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}

.mypage-user-else{
  display: flex;
  justify-content: space-around;
  margin-inline: auto;
  border: 1px solid var(--color-main);
  border-radius: 12px;
  margin-top: 20px;
  text-align: center;
}

.mypage-user-else{
  padding: 1rem 0;
}

.mypage-user-else  p:nth-of-type(1){
  font-size: var(--font-small);
}

.mypage-user-else  p:nth-last-of-type(1){
  margin-top: 10px;
  font-size: var(--font-extra-strong);
}

.mypage-user-benefit ul{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 50px;
  
}

.mypage-user-benefit .mypage-user-order,
.mypage-user-benefit .mypage-user-coupon

{
  padding: 0.8rem;
  border: 1px solid var(--color-light);
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}



.mypage-setting-wrap > li{
  padding: 1.2rem 0 ;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-basic);
  cursor: pointer;
}

.mypage-setting-wrap > p{
  padding: 1.2rem 0 ;

}

.mypage-logout-btn{
  display: block;
  width:200px;
  margin-inline: auto;
  padding:0.8rem 0.5rem;
  border-radius: 8px;
  color : var(--border-basic);
  border: 1px solid var(--border-basic);

}








/* Search area */
.search-input-wrap{
  margin-inline: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 500px;
  min-width: 200px;
  width: 100%;

}

.search-input-wrap form{
  transition : border-color .6s;
  position: relative;
  box-shadow: 0 0 10px #0003;
  overflow: hidden;
  border-radius: 28px;
  place-self: center;
}

.search-input-wrap form > div{
  display: flex;
}


.search-input-wrap form > div > a{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  width: 100%;
}


.search-input-wrap form > div svg{
  display: block;
  width: 30px;
  height: 30px;
  stroke: #82bd69;
  transition: all 400ms ease-in-out;
  
}

.search-input-wrap form > div svg:hover{
  stroke: #000;
  fill:#82bd69;
}


.search-input-wrap input{
  border: none;
  line-height: 26px;
  margin: 1rem .5rem; 
  font-family: 'Noto Serif KR';
  max-width: 300px;
  min-width: 80px;
  width: 100%;
  margin-left: 10px;
  
}

.search-input-wrap input[type=text]:focus-visible{
  border:1px solid red;
}

.search-input-classify{
  display: flex;
  gap: 1.5rem;
  padding-top: 30px;
  position: relative;

}

.search-input-classify::before{
position: absolute;


}

.search-input-wrap .search-input-classify > button{
  font-size: 14px;
  white-space: nowrap;
  padding: .8rem 1.2rem;
  color: #000;
  background: #fff;
}

.search-input-wrap .search-input-classify > button > svg{
  width: 20px;
  fill: #bcbcbc;
  height: 100%;
  vertical-align: sub;
  transition: fill 500ms ease-in-out;
}

.search-input-wrap .search-input-classify > button:hover > svg{
  fill: #252525;;
}

.search-input-wrap .search-input-classify > button > svg:nth-last-of-type(2){
  width: 28px;

}

.search-input-wrap form:focus-within{
  border-color: var(--color-main);

}

.search-input-wrap button{
  background: var(--color-main);
  flex: 1;
  width: 100%;
  padding: 0 2rem;
  color: #fff;
}




/* notice page */

/* common area */
.content-wrap{
  height: 100%;
  /* margin-inline: auto; */
}

.content-header{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid var(--border-basic);
  position: relative;
}

.content-back-btn{
  display: flex;
  place-content: center;
  place-items: center;
  padding: 1rem 2.5rem;

  margin-inline: auto;
  margin-top: 50px;
  border-radius: 8px;
  background: var(--border-basic);
  cursor: pointer;

}

.content-back-btn > button:hover{
  background-color: var(--color-strong);
  color: #fff;
  font-weight: 500;
}

.content-body{
  margin-top: 40px;
}

/* notice area */
.notice-table-header{
  display: grid;
  grid-template-columns: 1fr 7fr;
  place-items: center;
  padding:1rem 0;
  border-bottom: 2px solid #ccc;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 600;
}

.notice-table > li{
  display: grid;
  grid-template-columns: 1fr 6fr;
  place-content: center;
  padding:1rem 0;
  border-bottom: 1px solid #ccc;
}

.notice-table > li:hover{
  background-color: var(--color-light);
}


.notice-table > li .notice-title {
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notice-table > li .notice-title:hover {
  animation: squish 500ms ease-in-out;
}

@keyframes squish {
  50%{
    scale: 1.01 1;
  }
}

.notice-table > li .notice-order {
  place-self: center;
}


/* setting area */

.setting-toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.setting-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.setting-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.setting-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .setting-slider {
  background-color: var(--color-strong)
}

input:checked + .setting-slider:before {
  transform: translateX(26px);
}


/* setting area */
.setting-alert{
  display: flex;
  justify-content: space-between;
  place-items: center;
  padding: 1.2rem .5rem;
}

.setting-submenu{
  padding:1rem .5rem;
  border: 1px solid #eaeaea;
  border-radius: .8rem;
}

.setting-submenu > li{
  display: flex;
  justify-content: space-between;
  place-items: center;
  margin: 2rem 0;
  
}